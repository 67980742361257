@import '../../../../scss/theme-bootstrap';

.tabbed-products-formatter {
  margin: 25px auto;
  max-width: $max-width-large;
  padding: 0 20px;
  position: relative;
  &__header {
    margin: 0 auto 0;
  }
  &--max-width-768 {
    max-width: $tablet;
  }
  &--max-width-1024 {
    max-width: $max-width;
  }
  &--max-width-1280 {
    max-width: $max-width-medium;
  }
  &--max-width-1440 {
    max-width: $max-width-large;
  }
  &--max-width-1920 {
    max-width: $max-width-xlarge;
  }
}
